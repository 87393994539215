import '../scss/main.scss';

// eslint-disable-next-line func-names
window.onload = function () {
  // eslint-disable-next-line no-unused-vars, func-names
  window.submitForm = function () {
    const name = document.getElementById('inputName').value;
    const email = document.getElementById('inputEmail').value;
    const phone = document.getElementById('inputPhone').value;
    const query = document.getElementById('inputQuery').value;

    if (name.length > 1 && email.length > 6
      && phone.length > 0 && query.length > 5) {
      const body = `Name: ${name}\n     Phone: ${phone}\n     Email: ${email}\n     Query: ${query}`;
      // eslint-disable-next-line no-undef
      Email.send({
        SecureToken: '2f834997-baea-49bf-8f13-e8e628c5ae17',
        To: 'oakwoodtaxis@aol.com',
        From: 'website@blandford-oakwoodtaxis.co.uk',
        Subject: `Website message: ${name} : ${email}`,
        Body: body,
      }).then(
        (message) => {
          if (message === 'OK') {
            document.getElementById('form-message').innerText = 'Thank you, your query was successfully sent. We will get back to you soon.';
            document.getElementById('form-message-error').innerText = '';
          } else {
            console.log(message);
            document.getElementById('form-message-error').innerText = 'Sorry, there has been a problem sending the query. Please phone or email us directly.';
          }
          document.getElementById('form-btn').remove();
        },
      );
    } else {
      document.getElementById('form-message-error').innerText = 'Please complete all fields then submit';
    }
  };
};
